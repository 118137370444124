<div class="d-none d-lg-block">
  <app-documentation-navigation></app-documentation-navigation>
</div>
<div id="documentation" #documentation class="py-5">
  <h1 id="documentationIntroduction" class="display-5 text-center py-5">Introduction</h1>
  <div class="py-3"></div>
  <section class="section pb-5">
    <div class="py-5 text-area">
      <div class="row justify-content-center">
        <div class="mt-5 col-lg-8 p-4">
          <h2 id="documentationIntroductionPurpose" class="pb-5">Purpose</h2>
          <p>
            <span class="product-title">keldysh MesssageFlow</span> is a reactive message streams API that natively derives
            non-blocking integration patterns from reactive core principles.<br>
            Solely extending Project Reactor's interfaces,
            the declarative approach encapsulates messaging protocol specific implementation details, while backpressure
            is actively managed within the application and across the entire network stack.
          </p>
        </div>
        <div id="documentationIntroductionDisclaimer" class="mt-5 col-lg-8 p-4">
          <h2 class="pb-5">Disclaimer</h2>
          <p>
            The following documentation of <span>keldysh MessageFlow</span> shall provide a good overview and entry
            point to work with the library.
          </p>
          <p>
            In case of open questions or comments, feel free to contact us at
            <a href="mailto:contact@keldysh.io">contact&#64;keldysh.io</a>
          </p>
        </div>
        <div id="documentationIntroductionPrinciples" class="mt-5 col-lg-8 p-4">
          <h2 class="pb-5">Principles</h2>
          <div id="documentationIntroductionPrinciplesMessageOrientedArchitecture" class="mt-4 py-3">
            <h3 class="pb-4">Message Oriented Architecture</h3>
            <p>
              Message oriented architecture, typically key to event-driven application design, is fundamental to many modern
              digital landscapes. Characteristically are loosely coupled components, actively notified about new information.
              Communication is asynchronously provided through messaging middleware solutions (broker, bus, ...), allowing to
              subscribe to specified streams of information, commonly represented by message queues (topics, channels, ...).
              Widely accepted enterprise messaging protocols include AMQP, MQTT, Kafka, JMS, ActiveMQ Artemis Core, and others,
              all offering kinds of acknowledgment mechanisms for guaranteed delivery. Flow control, if available, however is
              mostly specific to the implementing vendor.
            </p>
          </div>
          <div id="documentationIntroductionPrinciplesReactiveProgramming" class="mt-4 py-3">
            <h3 class="pb-4">Reactive Programming</h3>
            <p>
              Reactive applications are characterized by reactive pipelines/streams, series of non-blocking,
              declarative, potentially asynchronous, operations, with backpressure managed by explicitly tracking demand.
              Fostering resilient, stable, and highly performant applications, at optimal resource utilization, reactive
              principles have rapidly gained great popularity. Project Reactor, one of the pioneering libraries, offers a simple
              yet comprehensive API (adapted by the Java 9+ standard), facilitating a clean and fluent coding style.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <h1 id="documentationUsage" class="display-5 text-center py-5">Usage</h1>
  <div class="py-3"></div>
  <section class="section pb-5">
    <div class="py-5 text-area">
      <div class="row justify-content-center">
        <div id="documentationUsageDeployToken" class="mt-5 col-lg-8 p-4">
          <h2 class="pb-5">Deploy Token</h2>
          <p>
            After subscribing the appropriate
            <a routerLink="/plans" role="button">Plan</a>, your subscriptions together
            with deploy tokens
            <!--          todo: use const declarations for routes?-->
            are located at <a routerLink="/secure/profile" role="button">Profile</a>. If there is no deploy token
            associated with the subscription yet, you may also (re-)create one there.
          </p>
        </div>
        <div id="documentationUsageBuildSetup" class="mt-5 col-lg-8 p-4">
          <h2 class="pb-5">Build Setup</h2>
          <p>
            With your deploy token at hand you may now simply add the <code>message-flow</code> dependency to your
            project. With your preferred build tool, please add <code>io.keldysh.public</code> repository like so:
          </p>
          <app-tabs
            [baseName]="'repositorySetup'"
            [tabs]="[
                { name: 'Gradle', content: gradleAddRepositoryTemplate },
                { name: 'Maven', content: mavenAddRepositoryTemplate }
              ]">
          </app-tabs>

          <ng-template #gradleAddRepositoryTemplate>
            <div class="row mt-3">
              <pre><code appCodeHighlight class="language-groovy">{{ gradleAddRepository }}</code></pre>
              <p>
                with <code>{{ gradleSecurity }}</code>, e.g. appended to your <code>~/.gradle/gradle.properties</code>.
              </p>
            </div>
          </ng-template>

          <ng-template #mavenAddRepositoryTemplate>
            <div class="row mt-3">
              <pre><code appCodeHighlight class="language-xml">{{ mavenAddRepository }}</code></pre>
              <p>
                with your deploy token defined, e.g. in setting <code>~/.m2/settings.xml</code> like so:
              </p>
              <pre><code appCodeHighlight class="language-xml">{{ mavenSecurity }}</code></pre>
            </div>
          </ng-template>

          <p>
            To add <code>message-flow</code> dependency:
          </p>
          <app-tabs
            [baseName]="'messageFlowDependencySetup'"
            [tabs]="[
                { name: 'Gradle', content: gradleMessageFlowDependencyTemplate },
                { name: 'Maven', content: mavenMessageFlowDependencyTemplate }
              ]">
          </app-tabs>

          <ng-template #gradleMessageFlowDependencyTemplate>
            <div class="row mt-3">
              <pre><code appCodeHighlight class="language-groovy">{{ gradleMessageFlowDependency }}</code></pre>
            </div>
          </ng-template>

          <ng-template #mavenMessageFlowDependencyTemplate>
            <div class="row mt-3">
              <pre><code appCodeHighlight class="language-xml">{{ mavenMessageFlowDependency }}</code></pre>
            </div>
          </ng-template>

          <p>
            For fully working examples see
            <a href="/get-started#setupBuildEnvironment"><i class="fa fa-arrow-right"></i> Get Started: Setup Build Environment</a>
            and
            <a href="/get-started#setupTestEnvironment"><i class="fa fa-arrow-right"></i> Get Started: Setup Test Environment</a>.
          </p>
          <p>
            Above examples already include spring boot starter dependency for autoconfiguration support. However, to
            keep things slim, you may only use the library's core dependency:
          </p>
          <app-tabs
            [baseName]="'coreDependencySetup'"
            [tabs]="[
                { name: 'Gradle', content: gradleTemplate },
                { name: 'Maven', content: mavenTemplate }
              ]">
          </app-tabs>

          <ng-template #gradleTemplate>
            <div class="row mt-3">
              <pre><code appCodeHighlight class="language-groovy">{{ messagingPackageDependencyGradle }}</code></pre>
            </div>
          </ng-template>

          <ng-template #mavenTemplate>
            <div class="row mt-3">
              <pre><code appCodeHighlight class="language-xml">{{ messagingPackageDependencyMaven }}</code></pre>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>

  <h1 id="documentationBuildingBlocks" class="display-5 text-center py-5">Building Blocks</h1>
  <div class="py-3"></div>
  <section class="section pb-5">
    <div class="py-5 text-area">
      <div class="row justify-content-center">
        <div id="documentationBuildingBlocksProtocolAbstraction" class="mt-5 col-lg-8 p-4">
          <h2 class="pb-5">Protocol Abstraction</h2>
          <p>
            Similar to classical enterprise integration patterns or concepts like JPA for databases,
            <span class="product-title">keldysh MessageFlow</span> introduces an abstraction layer to encapsulate
            underlying messaging protocols. This approach protects users from requiring to delve into proprietary client
            implementations and support SOLID principles where the choice of protocol purely is a matter of configuration.
          </p>
          <p>
            The abstraction layer consist of two interfaces. Message are defined by:
          </p>
          <pre><code appCodeHighlight class="language-java">{{ messageInterface }}</code></pre>
          <p>
            Where <code>payload()</code> returns the Java object (POJO) representation of the message.
          </p>
          <p>
            Client connections are managed within implementations of <code>MessageClient</code>, providing factory
            methods for the non-blocking endpoint equivalents <code>MessageSource</code> and <code>MessageSink</code>.
            <code>MessageClient</code> also encapsulates payload parsing. For simple JSON encoded payloads endpoints may
            be constructed conveniently, using:
          </p>
          <pre><code appCodeHighlight class="language-java">{{ messageClientInterface }}</code></pre>
        </div>
        <div id="documentationBuildingBlocksCoreElements" class="mt-5 col-lg-8 p-4">
          <h2 class="pb-5">Core Elements</h2>
          <div id="documentationBuildingBlocksCoreElementsNonBlockingEndpoints" class="mt-4 py-3">
            <h3 class="pb-4">Non-Blocking Endpoints</h3>
            <div class="mt-3 py-2">
              <h4 class="pb-3">Inbound</h4>
              <p>
                From a user perspective, reactive message streams are initialized with the non-blocking equivalent of an
                inbound endpoint in classical enterprise integration patterns:<br>
              </p>
              <pre><code appCodeHighlight class="language-java">{{ messageSourceDefinition }}</code></pre>
              <p>
                <code>MessageSource</code>s can be obtained either from a
                <code>MessageClient</code> instance or wrapping
                an existing <code>{{ publisher }}</code>:
              </p>
              <pre><code appCodeHighlight class="language-java">{{ wrapPublisherIntoSource }}</code></pre>
              <p>
                <span style="font-style: italic">Note: </span> While it is possible to directly
                <code>subscribe</code> to
                the <code>MessageSource</code> it is highly encouraged to first create a
                <code>MessageFlow</code> as shown
                in above example, making sure that messages are being acknowledged at the end of the stream.
              </p>
              <p>
                As for any regular reactive publisher the actual subscription at protocol level and hence event processing
                will only happen after <code>subscribe()</code>.
              </p>
            </div>
            <div class="mt-3 py-2">
              <h4 class="pb-3">Outbound</h4>
              <p>
                The reactive equivalent of an outbound endpoint is:
              </p>
              <pre><code appCodeHighlight class="language-java">{{ messageSinkDefinition }}</code></pre>
              <p>
                Sending messages is achieved either directly invoking <code>MessageSink</code>s'
                <code>publish</code> method like:
              </p>
              <pre><code appCodeHighlight class="language-java">{{ publishDirectly }}</code></pre>
              <p>
                Fluent style publishing, however, is offered by
                <a href="/documentation#documentationBuildingBlocksCoreElementsReactiveMessageStreamsApi" role="button"><code>MessageFlow</code></a>s'
                <code>connect</code> method:
              </p>
              <pre><code appCodeHighlight class="language-java">{{ publishFluent }}</code></pre>
            </div>
            <div id="documentationBuildingBlocksCoreElementsReactiveMessageStreamsApi" class="mt-4 py-3">
              <h3 class="pb-4">Reactive Message Streams API</h3>
              <p>
                <code>MessageFlow</code> (
                <a [routerLink]="['/documentation/javadoc']" [queryParams]="{ path: 'io/keldysh/messaging/MessageFlow.html' }" target="_blank">Javadoc: MessageFlow</a>
                ) is the central element to building integration flows.
              </p>
              <p>
                Representing a potentially infinite reactive stream of messages that can be processed with a rich set of
                non-blocking operations, this class essentially is the equivalent of Project Reactor's
                <a href="https://projectreactor.io/docs/core/release/api/reactor/core/publisher/Flux.html" target="_blank"><code>Flux</code></a>.
                The main difference is that <code>MessageFlow</code>, while mainly offering methods that act directly on
                the object, actually implements <code>{{ messagePublisher }}</code>. This choice promotes a clean code
                base and simultaneously allows for transparent acknowledgement management, to automatically secure
                guaranteed processing of all messages.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <h1 id="documentationConfiguration" class="display-5 text-center py-5">Configuration</h1>
  <div class="py-3"></div>
  <section class="section pb-5">
    <div class="py-5 text-area">
      <div class="row justify-content-center">
        <div id="documentationConfigurationPrinciples" class="mt-5 col-lg-8 p-4">
          <h2 class="pb-5">Principles</h2>
          <p>
            <span class="product-title">keldysh - MessageFlow</span> natively supports dependency injection (DI) systems.
            The choice of messaging protocols and respective properties therefore is purely a matter of configuration.
          </p>
          <p>
            The shared entry point is <code>MessageClient</code> (
            <a
              [routerLink]="['/documentation/javadoc']"
              [queryParams]="{ path: 'io/keldysh/messaging/MessageClient.html' }"
              target="_blank"
            >Javadoc: MessageClient</a>).<br>
            <code>Message</code> (
            <a
              [routerLink]="['/documentation/javadoc']"
              [queryParams]="{ path: 'io/keldysh/messaging/Message.html' }"
              target="_blank"
            >Javadoc: Message</a>) is the abstract representation on top of protocol specific messages and
            <code>PayloadParser</code> (
            <a
              [routerLink]="['/documentation/javadoc']"
              [queryParams]="{ path: 'io/keldysh/messaging/parsing/PayloadParser.html' }"
              target="_blank"
            >Javadoc: PayloadParser</a>) encapsulates protocol specific payload parsing.
            Developing client code however commonly does not require to directly access these objects.
          </p>
        </div>
        <div id="documentationConfigurationClientImplementations" class="mt-5 col-lg-8 p-4">
          <h2 class="pb-5">Client Implementations</h2>
          <p>
            Users may choose any of the provided <code>MessageClient</code> (
            <a
              [routerLink]="['/documentation/javadoc']"
              [queryParams]="{ path: 'io/keldysh/messaging/MessageClient.html' }"
              target="_blank"
            >Javadoc: MessageClient</a>) implementations or feel free to build a custom one. The scope of this library,
            however, only guarantees advanced principles like backpressure management, thread agnosticism, processing
            guarantees, and alike for included implementations.
          </p>
          <div id="documentationConfigurationClientImplementationsInMemory" class="mt-4 py-3">
            <h3 class="pb-4">In-Memory</h3>
            <p>
              <code>InMemoryBrokerClient</code> (<a
              [routerLink]="['/documentation/javadoc']"
              [queryParams]="{ path: 'io/keldysh/messaging/in_memory/InMemoryBrokerClient.html' }"
              target="_blank"
            >Javadoc: InMemoryBrokerClient</a>) is offers a high-performance in-memory client implementation to interact
              with proprietary <code>InMemoryBroker</code> (<a
              [routerLink]="['/documentation/javadoc']"
              [queryParams]="{ path: 'io/keldysh/messaging/in_memory/InMemoryBroker.html' }"
              target="_blank"
            >Javadoc: InMemoryBroker</a>) instances, attached to the client as an injected dependency.
            </p>
            <p>
              Instances of <code>MessageClient</code>, including an attached <code>InMemoryBroker</code> can either be
              instantiated, directly invoking respective constructor methods <code>InMemoryBrokerClient</code> or
              autoconfigured setting:
            </p>
            <pre><code appCodeHighlight class="language-yaml">{{ inMemoryAutoconfiguration }}</code></pre>
            <p>
              Autoconfigured broker instances are resolved from the autoconfigured <code>MessageClient</code>, using
              <code>InMemoryBroker broker = ((InMemoryBrokerClient) messageClient).broker()</code>.
            </p>
            <p>
              <code>MessageBroker</code> instances' routing behaviour can be configured directly, using
              <code>createMulticast(...)</code>. Alternatively, however, a convenient static utility is being provided to
              apply an entire <code>AddressModelConfiguration</code>. A full setup of an autoconfigured instance may for
              example be like:
            </p>
            <pre><code appCodeHighlight class="language-java">{{ inMemoryBrokerSetup }}</code></pre>
          </div>
          <div id="documentationConfigurationClientImplementationsActiveMqArtemis" class="mt-4 py-3">
            <h3 class="pb-4">ActiveMQ Artemis</h3>
            <p>
              <code>ArtemisClient</code> (<a
              [routerLink]="['/documentation/javadoc']"
              [queryParams]="{ path: 'io/keldysh/messaging/artemis/ArtemisClient.html' }"
              target="_blank"
            >Javadoc: ArtemisClient</a>) is the default implementation of <code>MessageClient</code> for connect to
              <a href="https://activemq.apache.org/components/artemis/">Apache ActiveMQ Artemis</a>, a high-performance
              messaging middleware, offering outstanding information security. Being a "real" messaging broker, compared to
              streaming solutions like Apache Kafka, it does also not discard messages after a predefined retention time.
            </p>
            <p>
              The general purpose approach, offers multiple connectors for different messaging protocols. Internally, all
              are however translated into the so-called Core protocol. Therefore, <code>ArtemisClient</code> directly
              facilitates the Core protocol, offering maximal flexibility without translational overhead.
            </p>
            <p>
              An autoconfigured instance of <code>ArtemisClient</code>, using a default set of properties for the
              Artemis specific <code>ServerLocator</code> class, can be obtained, adding the following to
              <code>application.yml</code>:
            </p>
            <pre><code appCodeHighlight class="language-yaml">{{ artemisAutoconfigurationAdvanced }}</code></pre>
            <p>
              For more maximum flexibility, any property of <code>ServerLocator</code> may however be customized.
              Available properties and respective defaults are defined in:
            </p>
            <pre><code appCodeHighlight class="language-java">{{ serverLocatorProperties }}</code></pre>
            <p>
              Configuration of the ActiveMQ Artemis instances is typically applied directly to the broker, using the
              web API or a
              <code>broker.xml</code> file. For simple deployment, a convenient utility method is provided to
              configure <code>TestContainer</code> instances of ActiveMQ Artemis, e.g. ran by
              <code>ActiveMQArtemisTestContainerExtension</code> or
              <code>ActiveMQArtemisStarterTestContainerExtension</code>.
              The overload of <code>setupArtemisBroker</code> offering the parameter
              <code>brokerFileSpecifier</code> will
              create a sample
              <code>{{ sampleBrokerXml }}</code> file, in the project root, that is being used during the
              tests, and therefore serves as a good starting point to setup the actual broker instance.
            </p>
            <p>
              Using Junit5, the following does create a test setup for scenarios running ActiveMQ Artemis TestContainers,
              and additionally write the test's broker configuration to <code>broker-message-flow-example.xml</code>:
            </p>
            <pre><code appCodeHighlight class="language-java">{{ artemisTestSetup }}</code></pre>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
