<!-- Nav tabs -->
<ul class="nav nav-tabs" [id]="baseName + 'Tab'" role="tablist">
  <li class="nav-item" role="presentation" *ngFor="let tab of tabs; let i = index">
    <button class="nav-link" [class.active]="i === 0" [id]="getTabId(tab.name)"
            data-bs-toggle="tab" [attr.data-bs-target]="'#' + getTabContentId(tab.name)"
            type="button" role="tab" [attr.aria-controls]="tab.name.toLowerCase()"
            [attr.aria-selected]="i === 0 ? 'true' : 'false'">{{ tab.name }}</button>
  </li>
</ul>

<!-- Tab content -->
<div class="tab-content" [id]="baseName + 'Content'">
  <div class="tab-pane fade" [class.show]="i === 0" [class.active]="i === 0"
       *ngFor="let tab of tabs; let i = index"
       [id]="getTabContentId(tab.name)" role="tabpanel"
       [attr.aria-labelledby]="getTabId(tab.name)">
    <ng-container *ngTemplateOutlet="tab.content"></ng-container>
  </div>
</div>


