<div id="get-started" #getStarted class="py-5">
  <h1 class="display-5 text-center py-5">Get Started</h1>
  <div class="py-3"></div>

  <!-- Introduction -->
  <section class="section">
    <div class="py-5 text-area">
      <div class="row justify-content-center">
        <div class="mt-5 col-lg-8 p-4">
          <h2 class="mb-5">Introduction</h2>
          <p>
            <span class="product-title">keldysh MessageFlow</span> is a high-level application framework that encapsulates messaging protocols into reactive streams.
          </p>
          <p>
            The framework enhances <a href="https://projectreactor.io/" target="_blank">Project Reactor</a>'s interfaces (adopted by the <a href="https://docs.oracle.com/javase%2F9%2Fdocs%2Fapi%2F%2F/java/util/concurrent/Flow.html" target="_blank">Java standard</a> version 9+) to provide capabilities reminiscent of traditional
            <a href="https://www.enterpriseintegrationpatterns.com/" target="_blank">Enterprise Integration Patterns</a>.
            Parsing, network adapters, thread management, acknowledgments, retry behavior and other technical aspects are transparently managed by protocol-specific implementations.
            Special focus is given to ensuring processing security and efficient backpressure management across the entire network stack.
            <!--Providing the freedom to chose message oriented middleware on-demand.-->
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- Requirements -->
  <section class="section">
    <div class="py-5 text-area">
      <div class="row justify-content-center">
        <div class="col-lg-8 p-4">
          <h2 class="mb-5">Requirements</h2>
          <ul>
            <li class="ms-3">Java Version {{ properties.minJavaVersion }}+</li>
            <li class="ms-3">A valid deploy token. Please, see our
              <a routerLink="/plans" role="button">Plans</a> for details.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <!-- Quickstart -->
  <section class="section">
    <div class="py-5 text-area">
      <div class="row justify-content-center">
        <div class="col-lg-8 p-4">
          <h2 class="mb-5">Quickstart</h2>
          <div class="py-3">
            <h3 class="mb-5">Example Application: Non-Blocking Handler Bridge</h3>
            <div class="row d-flex justify-content-center p-5">
              <!--                <img ngSrc="assets/images/non-blocking_handler_bridge_plain_I.png" class="img-fluid" style="max-width: 1024px; margin: auto" alt="Non-Blocking Handler Bridge" width="3190" height="784">-->
              <img ngSrc="assets/images/handler_bridge_optimized.svg" class="img-fluid" style="max-width: 1024px; margin: auto" alt="Non-Blocking Handler Bridge" width="3190" height="784">
            </div>
            <div class="mb-5">
              <p>
                The following is a fully functional application that subscribes to <code>source-queue</code>,
                applies a simple non-blocking mapping, and publishes the result to <code>destination-address</code>.
              </p>
              <pre><code appCodeHighlight class="language-java">{{ bridgeExample }}</code></pre>
              <p>
                <span style="font-style: italic">Note: </span>
                The established
                <code>MessageFlow</code> guarantees for every single message to be processed entirely up to
                <code>destination-address</code> before acknowledging to <code>source-queue</code>.
                The flow
                remains until application shutdown,
                applies backpressure management across the entire chain (broker-to-broker),
                and transparently retries (indefinite, backoff) in any case of failure or outage.
              </p>
              <div class="mb-5"></div>
              <p>
                The example leverages optional Spring Boot autoconfiguration of the
                <code>MessageClient</code> instance.
                Integrating with Apache ActiveMQ Artemis requires a minimal
                <br>
                <code>application.yml</code>:
              </p>
              <pre><code appCodeHighlight class="language-yaml">{{ bridgeExampleConfig }}</code></pre>
              <p>
                <span style="font-style: italic">Note: </span>
                The example assumes a broker being available with the required addresses and queues configured.
              </p>
              <p>
                <span style="font-style: italic">Note: </span>
                The core library does not depend on Spring Boot and autoconfiguration is absolutely optional.
              </p>
            </div>
          </div>

          <div id="setupBuildEnvironment" class="mb-3">
            <h3 class="mb-5">Setup Build Environment</h3>
            <div id="build-setup" class="mb-5">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs" id="buildSetupTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="gradle-build-setup-tab" data-bs-toggle="tab" data-bs-target="#gradleBuildSetup" type="button" role="tab" aria-controls="gradle" aria-selected="true">Gradle</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="maven-build-setup-tab" data-bs-toggle="tab" data-bs-target="#mavenBuildSetup" type="button" role="tab" aria-controls="maven" aria-selected="false">Maven</button>
                </li>
              </ul>
              <!-- Tab content -->
              <div class="tab-content mb-5" id="buildSetupTabContent">
                <div class="tab-pane fade show active" id="gradleBuildSetup" role="tabpanel" aria-labelledby="gradle-build-setup-tab">
                  <div class="row mt-3">
                    <p><code>build.gradle</code>: </p>
                    <pre><code appCodeHighlight class="language-groovy">{{ gradleSetupExample }}</code></pre>
                  </div>
                  <div class="row mt-3">
                    <p>In <code>~/.gradle/gradle.properties</code> append: </p>
                    <pre><code appCodeHighlight class="language-groovy">{{ gradleSetupSecurityExample }}</code></pre>
                  </div>
                </div>
                <div class="tab-pane fade" id="mavenBuildSetup" role="tabpanel" aria-labelledby="maven-build-setup-tab">
                  <div class="row mt-3">
                    <p><code>pom.xml</code></p>
                    <pre><code appCodeHighlight class="language-xml">{{ mavenSetupExample }}</code></pre>
                  </div>
                  <div class="row mt-3">
                    <p>In <code>~/.m2/settings.xml</code> append:</p>
                    <pre><code appCodeHighlight class="language-xml">{{ mavenSetupSecurityExample }}</code></pre>
                  </div>
                </div>
              </div>
            </div>
            <p class="fw-bold">
              Checkout our <a routerLink="/plans">Plans</a> to get your deploy token today.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Testing -->
  <section class="section">
    <div class="py-5 text-area">
      <div class="row justify-content-center">
        <div class="col-lg-8 p-4">
          <h2 class="mb-5">Testing</h2>
          <div class="mb-5">
            <p>
              <span class="product-title">keldysh MessageFlow</span> ships with dedicated test support packages.
            </p>
          </div>
          <h3 class="mb-5">Example Application Test: Non-Blocking Handler Bridge</h3>
          <div class="mb-5">
            <p>
              The following shows a minimal example to test above application, using the official ActiveMQ Artemis Docker image.
              The container lifecycle and configuration is manged entirely within the test environment
              and created configuration during testing is documented in an output file to support subsequent deployment processes.
            </p>
            <pre><code appCodeHighlight class="language-java">{{ testScenario }}</code></pre>
          </div>
          <h3 class="mb-5">Setup Test Environment</h3>
          <div id="setupTestEnvironment" class="mb-5">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs" id="testSetupTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="gradle-test-setup-tab" data-bs-toggle="tab" data-bs-target="#gradleTestSetup" type="button" role="tab" aria-controls="gradle" aria-selected="true">Gradle</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="maven-test-setup-tab" data-bs-toggle="tab" data-bs-target="#mavenTestSetup" type="button" role="tab" aria-controls="maven" aria-selected="false">Maven</button>
              </li>
            </ul>
            <!-- Tab content -->
            <div class="tab-content" id="testSetupTabContent">
              <div class="tab-pane fade show active" id="gradleTestSetup" role="tabpanel" aria-labelledby="gradle-test-setup-tab">
                <div class="row mt-3">
                  <p> Append to <code>dependencies</code> section in the <code>build.gradle</code>: </p>
                  <pre><code appCodeHighlight class="language-groovy">{{ gradleTestDependencies }}</code></pre>
                </div>
              </div>
              <div class="tab-pane fade" id="mavenTestSetup" role="tabpanel" aria-labelledby="maven-test-setup-tab">
                <div class="row mt-3">
                  <p>Append to <code>dependencies</code> node in the <code>pom.xml</code>:</p>
                  <pre><code appCodeHighlight class="language-xml">{{ mavenTestDependencies }}</code></pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
