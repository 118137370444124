import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-documentation-navigation',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './documentation-navigation.component.html',
  styleUrl: './documentation-navigation.component.scss'
})
export class DocumentationNavigationComponent implements OnInit {

  sections: {
    id: string,
    label: string,
    subSections: {
      id: string,
      label: string
      subSubSections: {
        id: string,
        label: string,
      }[]
    }[]
  }[] = [
    {
      id: 'documentationIntroduction',
      label: 'Introduction',
      subSections: [
        {id: 'documentationIntroductionPurpose', label: 'Purpose', subSubSections: []},
        {id: 'documentationIntroductionDisclaimer', label: 'Disclaimer', subSubSections: []},
        {id: 'documentationIntroductionPrinciples', label: 'Principles', subSubSections: []},
      ]
    },
    {
      id: 'documentationUsage',
      label: 'Usage',
      subSections: [
        {id: 'documentationUsageDeployToken', label: 'DeployToken', subSubSections: []},
        {id: 'documentationUsageBuildSetup', label: 'Build Setup', subSubSections: []},
      ]
    },
    {
      id: 'documentationBuildingBlocks',
      label: 'Building Blocks',
      subSections: [
        {
          id: 'documentationBuildingBlocksProtocolAbstraction',
          label: 'Protocol Abstraction',
          subSubSections: []
        },
        {
          id: 'documentationBuildingBlocksCoreElements',
          label: 'Core Elements',
          subSubSections: [
            {
              id: 'documentationBuildingBlocksCoreElementsNonBlockingEndpoints',
              label: 'Non-Blocking Endpoints'
            },
            {
              id: 'documentationBuildingBlocksCoreElementsReactiveMessageStreamsApi',
              label: 'Reactive Message Streams API'
            }
          ]
        },
      ]
    },
    {
      id: 'documentationConfiguration',
      label: 'Configuration',
      subSections: [
        {
          id: 'documentationConfigurationPrinciples',
          label: 'Principles',
          subSubSections: []
        },
        {
          id: 'documentationConfigurationClientImplementations',
          label: 'Client Implementations',
          subSubSections: [
            {
              id: 'documentationConfigurationClientImplementationsInMemory',
              label: 'In-Memory',
            },
            {
              id: 'documentationConfigurationClientImplementationsActiveMqArtemis',
              label: 'ActiveMQ Artemis',
            }
          ]
        },
      ]
    }
  ];

  activeSection: string = '';

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event: Event): void {
  //   const scrollPosition = window.scrollY;
  //   this.sections.forEach(section => {
  //     const element = document.getElementById(section.id);
  //     if (element) {
  //       const rect = element.getBoundingClientRect();
  //       const top = rect.top + window.pageYOffset;
  //       const bottom = top + rect.height;
  //       if (scrollPosition >= top && scrollPosition < bottom) {
  //         this.activeSection = section.id;
  //       }
  //     }
  //   });
  // }

  constructor(protected changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }
}
