import { Component } from '@angular/core';
import {properties} from "../properties";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-legal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './legal.component.html',
  styleUrl: './legal.component.scss'
})
export class LegalComponent {

  protected readonly properties = properties;
}
