<div id="features" #features class="py-5">
  <h1 class="display-5 text-center py-5">Features</h1>
  <div class="py-3"></div>
  <section class="section">
    <div class="py-5 text-area">
      <div class="row align-items-start justify-content-center">
        <div class="col-lg-5">
          <div class="p-4">
            <h2>Reactive Message Streams</h2>
            <h5 class="gray mb-4">Seamless & Resilient</h5>
            <p>
              Reactive principles for message processing: non-blocking, thread-agnostic, and managed backpressure
              across the entire flow, including the network stack. Guarantees no overwhelmed consumers, limits memory consumption,
              and transparently handles asynchronous tasks.
            </p>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="p-4">
            <h2>Processing Guarantees</h2>
            <h5 class="gray mb-4">ACID</h5>
            <p>
              Secure acknowledgment at the flow's end, combined with indefinite reconnects and retries, not only
              maintains flow stability at all times but also guarantees the successful processing—not just delivery—of
              every single message, even across restarts or power outages.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-start justify-content-center gx-4">
        <div class="col-lg-5">
          <div class="p-4">
            <h2>Application Centered</h2>
            <h5 class="gray mb-4">Developer-Friendly API</h5>
            <p>
              <code>MessageFlow</code> abstraction provides a concise API that adapts well-known concepts from reactive
              streams to messaging-specific requirements, ensuring efficient implementations while maintaining a clearly
              structured and readable codebase.
            </p>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="p-4">
            <h2>Portable</h2>
            <h5 class="gray mb-4">Write Once, Run Everywhere</h5>
            <p>
              The framework is designed for both cloud and edge computing. Following SOLID principles along with Eight Factor App
              best practices, it offers the flexibility to choose message-oriented middleware on-demand,
              ensuring fast and portable applications across environments.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="py-5"></div>
  <h1 class="display-5 text-center py-5">Implementations</h1>
  <div class="py-3"></div>
  <section class="section">
    <div class="container py-5 text-area">
      <div class="row align-items-center">
        <div class="col-lg-8 offset-lg-2">
          <div class="p-4">
            <h3 class="mb-4">Apache ActiveMQ Artemis</h3>
            <p>
              <code>ArtemisClient</code> is the go-to choice for message-oriented applications that require both speed and consistency.
              The broker is deployable anywhere (edge, cloud, or in-memory) and offers extensive opportunities for case-specific customization.
            </p>
            <p style="opacity: 0.5">
              <em>
                Note: During extensive tests, it never lost a single message even across power outages (<code>fsync</code> is done before acknowledgment).
              </em>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="py-3"></div>
  <section class="section">
    <div class="container py-5 text-area">
      <div class="row align-items-center">
        <div class="col-lg-8 offset-lg-2">
          <div class="p-4">
            <h3 class="mb-4">InMemory</h3>
            <p>
              <code>InMemoryClient</code> offers everything except persistence across application restarts.
              It is suited for broadcasting, maintaining state at runtime, and other similar workloads.
              This implementation also serves as a valuable starting point for testing and proof-of-concept implementations that do not require persistence guarantees.
            </p>
            <p>
              Unburdened by persistence requirements or parsing, its performance can reach millions of messages per second (verified on commodity laptop hardware),
              making it the preferred choice when performance is critical and compromises on information completeness are acceptable (as is typical for example in IoT applications).
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="py-3"></div>
  <section class="section">
    <div class="container py-5 text-area">
      <div class="row align-items-center">
        <div class="col-lg-8 offset-lg-2">
          <div class="p-4">
            <h3 class="mb-4">Future Directions</h3>
            <p>
              Further implementations of
              <code>MessageClient</code>, potentially supporting widely accepted middleware solutions like Apache Kafka or promising projects like Apache Pulsar, may be added in the future.
            </p>
            <p>
              Please feel free to
              <a href="mailto:contact@keldysh.io">contact</a> our staff at any time with requests, donations, or contributions.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
