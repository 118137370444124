import {HttpClient} from '@angular/common/http';
import {Component, DoCheck} from '@angular/core';
import {Product} from "./product";
import {environment} from "../../environments/environment";
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Price} from "./price";
import {FirebaseAuthenticationService} from "../firebase/firebase-authentication.service";
import {CheckoutModalService} from "./checkout-modal/checkout-modal.service";
import {LegalService} from "../legal/legal.service";

@Component({
  selector: 'app-plans',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './plans.component.html',
  styleUrl: './plans.component.scss'
})
export class PlansComponent {

  protected products!: Product[]

  protected isAuthenticated = false;

  constructor(
    private httpClient: HttpClient,
    protected authenticationService: FirebaseAuthenticationService,
    protected checkoutModalService: CheckoutModalService
  ) {
    this.fetchProducts();
    authenticationService.isAuthenticated().subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);
  }

  public fetchProducts() {
    this.httpClient
      .get<Product[]>(environment.apiPath + '/customer/products')
      .subscribe({
        next: (products: Product[]) => {
          // First, sort the prices within each product
          const productsWithSortedPrices = products.map(product => ({
            ...product,
            prices: product.prices.sort((a, b) => a.valueInChf - b.valueInChf)
          }));

          // Now, sort the products by the first price in their sorted prices array
          this.products = productsWithSortedPrices.sort((a, b) => {
            const priceA = a.prices[0]?.valueInChf ?? Infinity; // Use Infinity to handle products without prices
            const priceB = b.prices[0]?.valueInChf ?? Infinity;
            return priceA - priceB;
          });

          // console.log("Sorted Products:", this.products);
        },
        error: (error) => console.log('Error fetching products:', error.error)
      });
  }

  select(product: Product, price: Price, tryout: boolean) {
    this.checkoutModalService.select({
      price: price,
      product: product,
      tryout: tryout,
      quantity: 1
    })
  }
}
