import {Component} from '@angular/core';
import {CodeHighlightDirective} from "../code-highlight.directive";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {TabsComponent} from "../elements/tabs/tabs.component";
import {properties} from "../properties";
import {DocumentationNavigationComponent} from "./documentation-navigation/documentation-navigation.component";

@Component({
  selector: 'app-documentation',
  standalone: true,
  imports: [
    CodeHighlightDirective,
    RouterLink,
    TabsComponent,
    RouterLinkActive,
    DocumentationNavigationComponent
  ],
  templateUrl: './documentation.component.html',
  styleUrl: './documentation.component.scss'
})
export class DocumentationComponent {

  messagingPackageVersion = '4.0.2';

  messageInterface =
    'public interface Message<PAYLOAD> {\n' +
    '\n' +
    '    PAYLOAD payload();\n' +
    '\n' +
    '    Mono<Void> acknowledge();\n' +
    '\n' +
    '}';

  messageClientInterface =
    'public interface MessageClient extends Closeable {\n' +
    '\n' +
    '    default <PAYLOAD> MessageSink<PAYLOAD> sink(String outbound, Type payloadType) {\n' +
    '        ... \n' +
    '    }\n' +
    '\n' +
    '    default <PAYLOAD> MessageSource<PAYLOAD> source(String inbound, Type payloadType) {\n' +
    '        ... \n' +
    '    }\n' +
    '\n' +
    '    ... \n' +
    '\n' +
    '}'

  messageSourceDefinition = 'MessageSource<T> extends Publisher<Message<T>>, Supplier<MessageFlow<T>>';

  publisher = 'Publisher<T>';

  wrapPublisherIntoSource =
    'MessageSource<Integer> messageSource = MessageSource.wrap(Flux.range(0, 10));\n' +
    '\n' +
    'MessageFlow<Integer> messageflow = messageSource.flow();\n' +
    '\n' +
    '\/\/ potentially add handlers to the flow \n' +
    '\n' +
    'messageFlow.subscribe();\n' +
    ''

  messageSinkDefinition = ' MessageSink<PAYLOAD> extends Function<MessageFlow<PAYLOAD>, MessageFlow<PAYLOAD>>';

  publishMethod = 'MessageFlow<PAYLOAD> publish(Publisher<Message<PAYLOAD>> flow)';

  publishDirectly =
    'MessageSink<Long> messageSink = messageClient.sink(DESTINATION_ADDRESS, payloadClass);\n' +
    '  \n' +
    'messageSink\n' +
    '    .publish(MessageSource.wrap(Flux.range(0, 10)).flow())\n' +
    '    .subscribe();'

  publishFluent =
    'MessageSink<Long> messageSink = messageClient.sink(DESTINATION_ADDRESS, payloadClass);\n' +
    '  \n' +
    'MessageSource.wrap(Flux.range(0, 10)).flow().connect(messageSink).subscribe()\n'

  messagingPackageDependencyGradle = "implementation \'io.keldysh:messaging:" + this.messagingPackageVersion +"\'";
  messagingPackageDependencyMaven =
    '<dependency>\n' +
    '    <groupId>io.keldysh</groupId>\n' +
    '    <artifactId>messaging</artifactId>\n' +
    '    <version>' + this.messagingPackageVersion + '</version>\n' +
    '    <scope>compile</scope>\n' +
    '</dependency>\n'

  gradleAddRepository =
    'maven {\n' +
    '    url = uri(\'https://gitlab.com/api/v4/groups/' + properties.gitLabGroup + '/-/packages/maven\')\n' +
    '    credentials(HttpHeaderCredentials) {\n' +
    '        name = \'Deploy-Token\'\n' +
    // '            \/\/ expected to be defined e.g. as systemProp.keldyshMessageFlowToken=\<your token\> in ~/\.gradle/gradle\.properties\n' +
    '        value = System.properties[\'keldyshMessageFlowToken\']\n' +
    '    }\n' +
    '    authentication {\n' +
    '        header(HttpHeaderAuthentication)\n' +
    '    }\n' +
    '}\n'

  gradleSecurity = 'systemProp.keldyshMessageFlowToken=\<your deploy token\>'

  gradleMessageFlowDependency=  'implementation \'io.keldysh.public.application.starter:message-flow:' + properties.messageFlowVersion + '\'';

  mavenAddRepository =
    '    <repositories>\n' +
    '        <repository>\n' +
    '            <id>gitlab-maven</id>\n' +
    '            <url>https://gitlab.com/api/v4/groups/' + properties.gitLabGroup + '/-/packages/maven</url>\n' +
    '        </repository>\n' +
    '    </repositories>\n';

  mavenSecurity =
    '<settings xmlns="http://maven.apache.org/SETTINGS/1.0.0"\n' +
    '    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '    xsi:schemaLocation="http://maven.apache.org/SETTINGS/1.0.0 https://maven.apache.org/xsd/settings-1.0.0.xsd">\n' +
    '    <servers>\n' +
    '        <server>\n' +
    '            <id>gitlab-maven</id>\n' +
    '            <configuration>\n' +
    '                <httpHeaders>\n' +
    '                    <property>\n' +
    '                        <name>Deploy-Token</name>\n' +
    '                        <value>YOUR_DEPLOY_TOKEN</value>\n' +
    '                    </property>\n' +
    '                </httpHeaders>\n' +
    '            </configuration>\n' +
    '        </server>\n' +
    '    </servers>\n' +
    '</settings>\n'

  mavenMessageFlowDependency =
    '<dependency>\n' +
    '    <groupId>io.keldysh.public.application.starter</groupId>\n' +
    '    <artifactId>message-flow</artifactId>\n' +
    '    <version>' + properties.messageFlowVersion + '</version>\n' +
    '</dependency>\n';

  messagePublisher ='Publisher<Message<T>>'

  inMemoryAutoconfiguration =
    'keldysh:\n' +
    '  message-flow:\n' +
    '    in-memory:\n' +
    '      enabled: true'

  inMemoryBrokerSetup =
    'public class BrokerConfiguration {\n' +
    '\n' +
    '    public BrokerConfiguration(MessageClient messageClient) {\n' +
    '        final InMemoryBroker broker = ((InMemoryBrokerClient) messageClient).broker();\n' +
    '        AddressModelConfiguration addressModelConfiguration = new AddressModelConfiguration(Map.of(\n' +
    '                "sourceOutbound", List.of("destinationInbound")\n' +
    '        ));\n' +
    '\n' +
    '        BrokerSetupUtility.setupInMemoryBroker(broker, addressModelConfiguration);\n' +
    '    }\n' +
    '}';

  artemisAutoconfiguration=
    'keldysh:\n' +
    '  message-flow:\n' +
    '    artemis:\n' +
    '      host: "tcp://localhost"\n' +
    '      port: 61616\n' +
    '      credentials:\n' +
    '        username: artemis\n' +
    '        password: artemis';

  artemisAutoconfigurationAdvanced=
    'keldysh:\n' +
    '  message-flow:\n' +
    '    artemis:\n' +
    '      host: "tcp://localhost"\n' +
    '      port: 61616\n' +
    '      credentials:\n' +
    '        username: artemis\n' +
    '        password: artemis\n' +
    '      serverLocatorProperties: {}';

  serverLocatorProperties =
    'public class ServerLocatorProperties {\n' +
    '\n' +
    '    static int DEFAULT_INITIAL_CONNECTION_ATTEMPTS = -1;\n' +
    '    static int DEFAULT_RECONNECT_ATTEMPTS = -1;\n' +
    '    static int DEFAULT_FAILOVER_ATTEMPTS = -1;\n' +
    '    static int DEFAULT_RETRY_INTERVAL = 1000;\n' +
    '    static double DEFAULT_RETRY_INTERVAL_MULTIPLIER = 1.5;\n' +
    '    static long DEFAULT_MAX_RETRY_INTERVAL = 10_000;\n' +
    '    static final int DEFAULT_CONFIRMATION_WINDOW_SIZE = 8 * 1024 * 1024;\n' +
    '\n' +
    '    private Long clientFailureCheckPeriod;\n' +
    '    private Boolean cacheLargeMessagesClient;\n' +
    '    private Long connectionTTL;\n' +
    '    private Long callTimeout;\n' +
    '    private Long callFailoverTimeout;\n' +
    '    private Integer minLargeMessageSize;\n' +
    '    private Integer consumerWindowSize;\n' +
    '    private Integer consumerMaxRate;\n' +
    '    private Integer confirmationWindowSize = DEFAULT_CONFIRMATION_WINDOW_SIZE;\n' +
    '    private Integer producerWindowSize;\n' +
    '    private Integer producerMaxRate;\n' +
    '    private Boolean blockOnAcknowledge;\n' +
    '    private Boolean blockOnDurableSend;\n' +
    '    private Boolean blockOnNonDurableSend;\n' +
    '    private Boolean autoGroup;\n' +
    '    private String groupId;\n' +
    '    private Boolean preAcknowledge;\n' +
    '    private Integer ackBatchSize;\n' +
    '    private Boolean useGlobalPools;\n' +
    '    private Integer scheduledThreadPoolMaxSize;\n' +
    '    private Integer threadPoolMaxSize;\n' +
    '    private Integer retryInterval = DEFAULT_RETRY_INTERVAL;\n' +
    '    private Double retryIntervalMultiplier = DEFAULT_RETRY_INTERVAL_MULTIPLIER;\n' +
    '    private Long maxRetryInterval = DEFAULT_MAX_RETRY_INTERVAL;\n' +
    '    private Integer reconnectAttempts = DEFAULT_RECONNECT_ATTEMPTS;\n' +
    '    private Integer initialConnectAttempts = DEFAULT_INITIAL_CONNECTION_ATTEMPTS;\n' +
    '    private Integer failoverAttempts = DEFAULT_FAILOVER_ATTEMPTS;\n' +
    '    private String connectionLoadBalancingPolicyClassName;\n' +
    '    private Integer initialMessagePacketSize;\n' +
    '}'

  sampleBrokerXml = 'broker-<brokerFileSpecifier>.xml';

  artemisTestSetup =
    '@ExtendWith(ActiveMQArtemisStarterTestContainerExtension.class)\n' +
    'public class BasicAutoconfigurationExampleApplicationTest {\n' +
    '\n' +
    '    @BeforeAll\n' +
    '    static void init(ClientSessionFactory sessionFactory) {\n' +
    '        final AddressModelConfiguration addressModelConfiguration = new AddressModelConfiguration(Map.of(\n' +
    '                MessageFlowAutoConfigurationExampleApplication.PubSubExampleComponent.TEST_ADDRESS,\n' +
    '                List.of(MessageFlowAutoConfigurationExampleApplication.PubSubExampleComponent.TEST_QUEUE)\n' +
    '        ));\n' +
    '\n' +
    '        // The last parameter specifies the addresses configuration to be written to broker-message-flow-example.xml .\n' +
    '        BrokerSetupUtility.setupArtemisBroker(sessionFactory, addressModelConfiguration, "message-flow-example");\n' +
    '    }\n' +
    '\n' +
    '    \/\/ test implementation goes here ...\n' +
    '\n' +
    '}'
}
