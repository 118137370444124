import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SafeUrlPipe} from "./safe-url.pipe";

@Component({
  selector: 'app-javadoc',
  standalone: true,
  imports: [SafeUrlPipe],
  templateUrl: './javadoc.component.html',
  styleUrl: './javadoc.component.scss'
})
export class JavadocComponent implements OnInit{

  javadocPath: string;

  constructor(private route: ActivatedRoute) {
    this.javadocPath = 'assets/javadoc/index.html'; // Default Javadoc file
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['path']) {
        this.javadocPath = 'assets/javadoc/' + params['path'];
      }
    });
  }
}
