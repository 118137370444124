import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {filter} from "rxjs";
import {LoginComponent} from "./login/login.component";
import {LoginModalService} from "./login/login-modal/login-modal.service";
import {FirebaseAuthenticationService} from "./firebase/firebase-authentication.service";
import {LoginModalComponent} from "./login/login-modal/login-modal.component";
import {CheckoutModalComponent} from "./plans/checkout-modal/checkout-modal.component";
import {LoadingComponent} from "./loading/loading.component";
import {ActionConfirmationModalComponent} from "./action-confirmation-modal/action-confirmation-modal.component";
import {StagingBannerComponent} from "./staging-banner/staging-banner.component";

declare var VANTA: any;
// declare var VANTA: {
//     TOPOLOGY: (arg0: {
//         el: any; THREE: typeof THREE; // Pass THREE.js as a parameter
//         mouseControls: boolean; touchControls: boolean; gyroControls: boolean; minHeight: number; minWidth: number; scale: number; scaleMobile: number; color: number; backgroundColor: number;
//     }) => any;
// };
// import TOPOLOGY from 'vanta/dist/vanta.topology.min';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive, NgClass, NgIf, LoginComponent, AsyncPipe, LoginModalComponent, CheckoutModalComponent, LoadingComponent, ActionConfirmationModalComponent, StagingBannerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  title = 'keldysh-message-flow';

  @ViewChild('bodyBackground') vantaElement!: ElementRef;
  vantaEffect: any;
  navbarCollapsed = true;
  showFooter = true;

  constructor(
    private router: Router,
    protected loginModalService: LoginModalService,
    protected authenticationService: FirebaseAuthenticationService
  ) {
    this.router
      .events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        // Here, `event` is strictly typed as NavigationEnd, avoiding the type mismatch error
        this.showFooter = (<NavigationEnd>event).urlAfterRedirects !== '/';
      });
  }

  pageLoaded = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 0);

    // const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
  }


  ngAfterViewInit(): void {
    // this.vantaEffect =
    //   VANTA.TOPOLOGY({
    //     el: this.vantaElement.nativeElement,
    //     // el: "#bodyBackground",
    //     THREE: THREE, // Pass THREE.js as a parameter
    //     mouseControls: true,
    //     touchControls: true,
    //     gyroControls: false,
    //     minHeight: 200.00,
    //     minWidth: 200.00,
    //     scale: 10.00,
    //     scaleMobile: 5.00,
    //     color: 0x999999,
    //     backgroundColor: 0x0
    //   });
  }

  toggleCollapsed(): void {
    this.navbarCollapsed = !this.navbarCollapsed;
  }

  ngOnDestroy(): void {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  }

  protected readonly window = window;
}
