import {Component, Input, TemplateRef} from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {

  @Input() baseName: string = '';
  @Input() tabs: { name: string, content: TemplateRef<any> }[] = [];

  getTabId(tabName: string): string {
    return `${this.baseName}-${tabName.toLowerCase().replace(/\s+/g, '-')}-tab`;
  }

  getTabContentId(tabName: string): string {
    return `${this.baseName}-${tabName.toLowerCase().replace(/\s+/g, '-')}`;
  }
}
