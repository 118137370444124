import {Component} from '@angular/core';
import {LoadingService} from "./loading.service";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {

  isLoading!: boolean;

  constructor(service: LoadingService) {
    service.isLoading.subscribe(value => this.isLoading = value);
  }
}
