import {AfterViewInit, Directive, ElementRef, Renderer2} from '@angular/core';
import hljs from 'highlight.js';

@Directive({
  selector: '[appCodeHighlight]',
  standalone: true
})
export class CodeHighlightDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {
    // this.addCopyButton();
  }

  ngAfterViewInit() {
    hljs.highlightElement(this.el.nativeElement);
  }

  // private addCopyButton() {
  //   const button = this.renderer.createElement('button');
  //   const icon = this.renderer.createElement('i');
  //
  //   this.renderer.addClass(icon, 'fa');
  //   this.renderer.addClass(icon, 'fa-copy');
  //   this.renderer.appendChild(button, icon);
  //   this.renderer.setStyle(button, 'position', 'absolute');
  //   this.renderer.setStyle(button, 'top', '10px');
  //   this.renderer.setStyle(button, 'right', '10px');
  //   this.renderer.setStyle(button, 'background', 'transparent');
  //   this.renderer.setStyle(button, 'border', 'none');
  //   this.renderer.setStyle(button, 'cursor', 'pointer');
  //
  //   this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
  //   this.renderer.appendChild(this.el.nativeElement, button);
  //
  //   this.renderer.listen(button, 'click', () => this.copyCode(button));
  // }
  //
  // private copyCode(button: HTMLElement) {
  //   const preElement = button.parentElement;
  //   if (preElement) {
  //     const codeElement = preElement.querySelector('code');
  //     if (codeElement) {
  //       const code = codeElement.textContent;
  //       if (code) {
  //         navigator.clipboard.writeText(code).then(() => {
  //           console.log('Code copied to clipboard!');
  //         });
  //       } else {
  //         console.error('No code element found to copy');
  //       }
  //     }
  //   }
  // }
}
