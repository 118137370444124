import {Routes} from '@angular/router';
import {GetStartedComponent} from "./get-started/get-started.component";
import {HeroComponent} from "./hero/hero.component";
import {FeaturesComponent} from "./features/features.component";
import {PlansComponent} from "./plans/plans.component";
import {authenticationGuard} from "./authentication.guard";
import {LegalComponent} from "./legal/legal.component";
import {DocumentationComponent} from "./documentation/documentation.component";
import {JavadocComponent} from "./documentation/javadoc/javadoc.component";

export const routes: Routes = [
  {path: '', component: HeroComponent},
  {path: 'get-started', component: GetStartedComponent},
  {path: 'features', component: FeaturesComponent},
  {path: 'plans', component: PlansComponent},
  {path: 'legal', component: LegalComponent},
  {path: 'documentation', component: DocumentationComponent},
  {path: 'documentation/javadoc', component: JavadocComponent},
  // {path: 'login', component: CustomerComponent},
  {
    path: 'secure',
    canActivate: [authenticationGuard],
    loadChildren: () => import('./secure/secure.routes').then(m => m.routes)
  }
];
