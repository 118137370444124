<div id="legal" #legal class="py-5">
  <h1 class="display-5 text-center py-5">Licenses</h1>
  <div class="py-3"></div>
  <section class="section" [id]=properties.privateLicenseAnchor>
    <div class="container py-5 text-area">
      <div class="row align-items-center">
        <div class="col-lg-6 offset-lg-3">
          <div class="p-5">
            <h3 class="py-5">KELDYSH - MESSAGEFLOW SOFTWARE LICENSE AGREEMENT - PERSONAL LICENSE</h3>
            <p>
              <strong>IMPORTANT - READ CAREFULLY:</strong> By downloading, installing, or using the "keldysh - MessageFlow" libraries (the "Software"), you agree to be bound by the terms of this Agreement. This Agreement is a legal document between you (an individual user) and keldysh ("Licensor"), headquartered in Switzerland and legally represented by Dr. Peter Machon. If you do not agree to the terms of this Agreement, do not download, install, or use the Software.
            </p>
            <h4 class="py-3">1. GRANT OF LICENSE</h4>
            <p>Licensor hereby grants you a non-exclusive, non-transferable license to use the Software for personal projects and in-house development, subject to adherence to all the terms and conditions of this Agreement. This license is granted solely to you, the individual downloader, for your personal use.</p>
            <p><strong>Permitted Uses:</strong></p>
            <ul>
              <li>
                <strong>Personal Projects:</strong> You are permitted to use the Software as an integral component of personal software projects not associated with any commercial or organizational purposes.
              </li>
              <li>
                <strong>In-House Development for Employer:</strong> You may use the Software internally within your own business enterprise or your employer’s organization for creating, developing, and testing software applications specific to your or your employer's business operations.
              </li>
            </ul>
            <h4 class="py-3">2. RESTRICTIONS</h4>
            <ul>
              <li>
                <strong>No Sharing or Concurrent Use:</strong> The license is assigned to one user and cannot be shared or used by more than one individual.
              </li>
              <li>
                <strong>No Redistribution:</strong> You may not distribute, sell, lease, rent, lend, or sublicense the Software to another party.
              </li>
              <li>
                <strong>No Modification or Derivative Works:</strong> You may not modify, merge, or create derivative works based upon the Software without prior written consent from the Licensor.
              </li>
              <li>
                <strong>No Reverse Engineering:</strong> You are not permitted to reverse engineer, decompile, or disassemble the Software.
              </li>
            </ul>
            <h4 class="py-3">3. INTELLECTUAL PROPERTY RIGHTS</h4>
            <p>All intellectual property in the Software, including any software updates, modifications, and any copies made thereof, remain with the Licensor or its suppliers. This Agreement grants no rights to use such content, except as expressly outlined herein.</p>
            <h4 class="py-3">4. SOFTWARE UPDATES</h4>
            <p>The terms of this Agreement will govern any upgrades or updates to the Software provided by Licensor that replace and/or supplement the original Software, unless such upgrade or update is accompanied by a separate license in which case the terms of that license will govern.</p>
            <h4 class="py-3">5. WARRANTIES AND DISCLAIMERS</h4>
            <p>THE SOFTWARE IS PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. LICENSOR DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.</p>
            <h4 class="py-3">6. LIMITATION OF LIABILITY</h4>
            <p>IN NO EVENT WILL LICENSOR BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS, DATA, OR USE, WHETHER IN AN ACTION IN CONTRACT OR TORT, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF, OR INABILITY TO USE, THE SOFTWARE.</p>
            <h4 class="py-3">7. TERMINATION</h4>
            <p>This Agreement is effective until terminated. It will terminate automatically without notice from Licensor if you fail to comply with any provision contained herein. Upon termination, you must cease all use of the Software and destroy all copies made thereof.</p>
            <h4 class="py-3">8. GOVERNING LAW</h4>
            <p>This Agreement shall be governed by and construed in accordance with the laws of Switzerland, without giving effect to its conflict of laws provisions.</p>
            <h4 class="py-3">9. ACKNOWLEDGMENT</h4>
            <p>BY DOWNLOADING, INSTALLING, OR USING THE SOFTWARE, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS. YOU ALSO AGREE THAT THIS AGREEMENT IS THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN YOU AND THE LICENSOR, WHICH SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT, ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN YOU AND LICENSOR RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="py-3"></div>
  <section class="section" [id]=properties.redistributionLicenseAnchor>
    <div class="container py-5 text-area">
      <div class="row align-items-center">
        <div class="col-lg-6 offset-lg-3">
          <div class="p-5">
            <h3 class="py-5">KELDYSH - MESSAGEFLOW SOFTWARE LICENSE AGREEMENT - REDISTRIBUTION LICENSE</h3>
            <p>
              <strong>IMPORTANT - READ CAREFULLY:</strong> By downloading, installing, or using the "keldysh - MessageFlow" libraries (the "Software"), you agree to be bound by the terms of this Agreement. This Agreement is a legal document between you (an individual user or entity) and keldysh ("Licensor"), headquartered in Switzerland and legally represented by Dr. Peter Machon. If you do not agree to the terms of this Agreement, do not download, install, or use the Software.
            </p>
            <h4 class="py-3">1. GRANT OF LICENSE</h4>
            <p>Licensor hereby grants you a non-exclusive, floating single-user license to use the Software for personal projects, in-house development, and integration into and redistribution within your own software products, provided that the final products offer significant additional value. This license is subject to adherence to all the terms and conditions of this Agreement.</p>
            <p><strong>Permitted Uses:</strong></p>
            <ul>
              <li>
                <strong>Personal Projects:</strong> You are permitted to use the Software as an integral component of personal software projects not associated with any commercial or organizational purposes.
              </li>
              <li>
                <strong>In-House Development for Employer:</strong> You may use the Software internally within your own business enterprise or your employer’s organization for creating, developing, and testing software applications specific to your or your employer's business operations.
              </li>
              <li>
                <strong>Software Integration and Redistribution:</strong> You may integrate the Software into your software products and redistribute these products to other businesses or end users. When redistributing the Software as part of a larger product, you must include visible credit to "keldysh - MessageFlow" in a manner akin to common open-source attribution practices, even if the product is rebranded.
              </li>
            </ul>
            <h4 class="py-3">2. RESTRICTIONS</h4>
            <ul>
              <li>
                <strong>No Sharing or Concurrent Use:</strong> The license allows for a floating single user, meaning it can be used by different individuals but only one at a time.
              </li>
              <li>
                <strong>Controlled Redistribution:</strong> Redistribution is permitted only as part of your own software products. You must ensure that the Software is not redistributed as standalone or as part of an open-source project without appropriate credit.
              </li>
              <li>
                <strong>No Unauthorized Modification or Derivative Works:</strong> You may not modify, merge, or create derivative works based upon the Software without incorporating them into your own products that add significant additional value.
              </li>
              <li>
                <strong>No Reverse Engineering:</strong> You are not permitted to reverse engineer, decompile, or disassemble the Software.
              </li>
            </ul>
            <h4 class="py-3">3. INTELLECTUAL PROPERTY RIGHTS</h4>
            <p>All intellectual property in the Software, including any software updates, modifications, and any copies made thereof, remain with the Licensor or its suppliers. This Agreement grants no rights to use such content, except as expressly outlined herein.</p>
            <h4 class="py-3">4. SOFTWARE UPDATES</h4>
            <p>The terms of this Agreement will govern any upgrades or updates to the Software provided by Licensor that replace and/or supplement the original Software, unless such upgrade or update is accompanied by a separate license in which case the terms of that license will govern.</p>
            <h4 class="py-3">5. WARRANTIES AND DISCLAIMERS</h4>
            <p>THE SOFTWARE IS PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. LICENSOR DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.</p>
            <h4 class="py-3">6. LIMITATION OF LIABILITY</h4>
            <p>IN NO EVENT WILL LICENSOR BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS, DATA, OR USE, WHETHER IN AN ACTION IN CONTRACT OR TORT, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF, OR INABILITY TO USE, THE SOFTWARE.</p>
            <h4 class="py-3">7. TERMINATION</h4>
            <p>This Agreement is effective until terminated. It will terminate automatically without notice from Licensor if you fail to comply with any provision contained herein. Upon termination, you must cease all use of the Software and destroy all copies made thereof.</p>
            <h4 class="py-3">8. GOVERNING LAW</h4>
            <p>This Agreement shall be governed by and construed in accordance with the laws of Switzerland, without giving effect to its conflict of laws provisions.</p>
            <h4 class="py-3">9. ACKNOWLEDGMENT</h4>
            <p>BY DOWNLOADING, INSTALLING, OR USING THE SOFTWARE, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS. YOU ALSO AGREE THAT THIS AGREEMENT IS THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN YOU AND THE LICENSOR, WHICH SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT, ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN YOU AND LICENSOR RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="py-3"></div>
  <section *ngIf="false" class="section" [id]=properties.supportContractAnchor>
    <div class="container py-5 text-area">
      <div class="row align-items-center">
        <div class="col-lg-6 offset-lg-3">
          <div class="p-5">
            <h3 class="py-5">KELDYSH - PROFESSIONAL SUPPORT SERVICES AGREEMENT</h3>
            <p>
              <strong>IMPORTANT - READ CAREFULLY:</strong> This Professional Support Services Agreement ("Agreement") is a legal document between the registered entity receiving the services ("Customer") and Keldysh ("Service Provider"), headquartered in Switzerland and legally represented by Dr. Peter Machon. By purchasing or using the Professional Support services ("Services"), you agree to be bound by the terms of this Agreement. If you do not agree to the terms of this Agreement, do not purchase or use the Services.
            </p>
            <h4 class="py-3">1. SCOPE OF SERVICES</h4>
            <p>Service Provider agrees to provide Customer with professional support and troubleshooting assistance through online support tickets and direct phone support under the following terms:</p>
            <ul>
              <li>
                <strong>Support Tickets:</strong> Customer is entitled to submit up to five (5) support tickets per year. Each ticket allows for up to eight (8) hours of troubleshooting or hands-on development support.
              </li>
              <li>
                <strong>Phone Support:</strong> Support is available in English and German. Phone support is intended to address urgent issues and facilitate quick resolutions.
              </li>
              <li>
                <strong>Guaranteed Response Time:</strong> Service Provider guarantees a response time of within 12 hours from the receipt of any support request.
              </li>
            </ul>
            <h4 class="py-3">2. SERVICE LEVEL AGREEMENT (SLA)</h4>
            <p>This SLA outlines the standards and response times to which the Service Provider commits. Failure to meet these standards may entitle the Customer to specific remedies, which will be negotiated on a case-by-case basis.</p>
            <h4 class="py-3">3. FEES AND PAYMENT</h4>
            <ul>
              <li>
                <strong>Fees:</strong> Customer agrees to pay the fees associated with the Services as stipulated in the official licensing terms and any additional service-specific documentation.
              </li>
              <li>
                <strong>Payment Terms:</strong> Fees are due and payable upon the execution of this Agreement and prior to the commencement of any support services.
              </li>
            </ul>
            <h4 class="py-3">4. DATA PROTECTION AND PRIVACY</h4>
            <ul>
              <li>
                <strong>Compliance:</strong> Both parties agree to comply with all applicable data protection laws regarding the personal data processed in connection with this Agreement.
              </li>
              <li>
                <strong>Data Handling:</strong> The Service Provider will take all necessary precautions to ensure the security and confidentiality of any data transmitted during support activities.
              </li>
            </ul>
            <h4 class="py-3">5. LIMITATIONS OF LIABILITY</h4>
            <ul>
              <li>
                <strong>General Limitations:</strong> In no event will Service Provider be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, or use, arising out of the Services, whether in an action in contract or tort, even if advised of the possibility of such damages.
              </li>
              <li>
                <strong>Caps on Liability:</strong> Total liability under this Agreement shall not exceed the amount paid by the Customer for the Services during the 12 months prior to the claim.
              </li>
            </ul>
            <h4 class="py-3">6. INDEMNIFICATION</h4>
            <p>The Service Provider shall indemnify and hold harmless the Customer against any and all claims, damages, liabilities, costs, and expenses arising from the Service Provider's gross negligence or willful misconduct.</p>
            <h4 class="py-3">7. DISPUTE RESOLUTION</h4>
            <p>In the event of any dispute arising out of or related to this Agreement, the parties will attempt to resolve the dispute through friendly consultation. If the dispute is not resolved within a reasonable period, then either party may submit the dispute to mediation. If the dispute is not resolved through mediation, it shall be submitted for binding arbitration under the rules of the Arbitration Association of Switzerland.</p>
            <h4 class="py-3">8. TERM AND TERMINATION</h4>
            <ul>
              <li>
                <strong>Term:</strong> This Agreement shall commence on the date of purchase of the Services and shall continue in effect until the completion of the contracted support tickets or one year from the date of purchase, whichever comes first.
              </li>
              <li>
                <strong>Termination for Cause:</strong> Either party may terminate this Agreement upon written notice if the other party materially breaches any of the terms of this Agreement and fails to cure such breach within thirty (30) days of receipt of the breach notice.
              </li>
            </ul>
            <h4 class="py-3">9. GENERAL PROVISIONS</h4>
            <ul>
              <li>
                <strong>Force Majeure:</strong> Neither party shall be liable for any failure to perform its obligations under this Agreement if prevented by conditions beyond reasonable control, including but not limited to natural disasters, government restrictions, or wars.
              </li>
              <li>
                <strong>Governing Law:</strong> This Agreement shall be governed by and construed in accordance with the laws of Switzerland, without giving effect to its conflict of laws provisions.
              </li>
              <li>
                <strong>Entire Agreement:</strong> This Agreement constitutes the entire agreement between the parties with respect to its subject matter and supersedes all prior or contemporaneous agreements, negotiations, or other communications between the parties.
              </li>
            </ul>
            <h4 class="py-3">10. ACKNOWLEDGMENT</h4>
            <p>BY PURCHASING OR USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS. YOU ALSO AGREE THAT THIS AGREEMENT IS THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN YOU AND THE SERVICE PROVIDER, WHICH SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT, ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN YOU AND THE SERVICE PROVIDER RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
