import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoginModalService {

  private _loginRequest = new Subject<string | null>();
  // private modalVisible = new BehaviorSubject<boolean>(false);

  loginAndRoute(url: string) {
    this._loginRequest.next(url)
  }

  login(): void {
    this._loginRequest.next(null);
    // this.modalVisible.next(true);
  }

  get loginRequest() {
    return this._loginRequest.asObservable();
  }

  // hideModal(): void {
  //   this.modalVisible.next(false);
  // }

  // getModalState() {
  //   return this.modalVisible.asObservable();
  // }
}
