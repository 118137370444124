<div class="modal fade" id="actionConfirmationModal" #actionConfirmationModal tabindex="-1" role="dialog" aria-labelledby="actionConfirmationModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="hideModal()"></button>
      </div>
      <div class="modal-body">
        <p>{{ modalState?.content }}</p>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="submit()">Ok</button>
      </div>
    </div>
  </div>
</div>


<!--<div class="modal fade" id="checkoutModal" #checkoutModal tabindex="-1" aria-labelledby="checkoutModalLabel" aria-hidden="true">-->
<!--  <div class="modal-dialog">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-header">-->
<!--        <h5 class="modal-title" id="checkoutModalLabel">Checkout</h5>-->
<!--        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
<!--      </div>-->
<!--      <div *ngIf="!isAuthenticated">-->
<!--        <div class="modal-body">-->
<!--          <p class="py-3">Please, sign up and log in before proceeding to checkout.</p>-->
<!--          <app-login [isSignUpMode]="true"></app-login>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div *ngIf="isAuthenticated">-->
<!--        <div class="modal-body">-->
<!--          <app-checkout></app-checkout>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
