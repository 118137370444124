import {Component} from '@angular/core';
import {CodeHighlightDirective} from "../code-highlight.directive";
import {RouterLink} from "@angular/router";
import {NgOptimizedImage} from "@angular/common";
import {properties} from "../properties";

@Component({
  selector: 'app-get-started',
  standalone: true,
  imports: [
    CodeHighlightDirective,
    RouterLink,
    NgOptimizedImage
  ],
  templateUrl: './get-started.component.html',
  styleUrl: './get-started.component.scss'
})
export class GetStartedComponent {

  // gitLabGroup = '84921789';
  // minJavaVersion = '17';
  // messageFlowVersion = '1.0.2';
  // messagingVersion = '3.0.3';
  // springBootVersion = '3.2.4';
  // springDependencyManagementVersion = '1.1.4';
  // lombokVersion = '8.6';
  // projectReactorVersion = '3.5.4';
  // testcontainerVersion = '1.19.3'

  gradleSetupExample =
    'plugins {\n' +
    '    id \'java\'\n' +
    '    id \'org.springframework.boot\' version \'' + properties.springBootVersion + '\'\n' +
    '    id \'io.spring.dependency-management\' version \'' + properties.springDependencyManagementVersion + '\'\n' +
    '}\n' +
    '\n' +
    'group \'\<your group\>\'\n' +
    'version \'\<your version\>\'\n' +
    '\n' +
    'repositories {\n' +
    '    mavenCentral()\n' +
    '    maven {\n' +
    '        url = uri(\'https://gitlab.com/api/v4/groups/' + properties.gitLabGroup + '/-/packages/maven\')\n' +
    '        credentials(HttpHeaderCredentials) {\n' +
    '            name = \'Deploy-Token\'\n' +
    // '            \/\/ expected to be defined e.g. as systemProp.keldyshMessageFlowToken=\<your token\> in ~/\.gradle/gradle\.properties\n' +
    '            value = System.properties[\'keldyshMessageFlowToken\']\n' +
    '        }\n' +
    '        authentication {\n' +
    '            header(HttpHeaderAuthentication)\n' +
    '        }\n' +
    '    }\n' +
    '}\n' +
    '\n' +
    'dependencies {\n' +
    '    implementation \'io.keldysh.public.application.starter:message-flow:' + properties.messageFlowVersion + '\'\n' +
    '}'

  gradleSetupSecurityExample = 'systemProp.keldyshMessageFlowToken=\<your deploy token\>'

  mavenSetupExample =
    '<project xmlns="http://maven.apache.org/POM/4.0.0"\n' +
    '         xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '         xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 http://maven.apache.org/xsd/maven-4.0.0.xsd">\n' +
    '    <modelVersion>4.0.0</modelVersion>\n' +
    '\n' +
    '    <groupId>YOUR_GROUP</groupId>\n' +
    '    <artifactId>YOUR_ARTIFACT_ID</artifactId>\n' +
    '    <version>YOUR_VERSION</version>\n' +
    '    <packaging>jar</packaging>\n' +
    '\n' +
    '    <name>YOUR_PROJECT_NAME</name>\n' +
    '    <description>YOUR_DESCRIPTION</description>\n' +
    '\n' +
    '    <properties>\n' +
    '        <java.version>' + properties.minJavaVersion + '</java.version>\n' +
    '        <spring.boot.version>' + properties.springBootVersion + '</spring.boot.version>\n' +
    '    </properties>\n' +
    '\n' +
    '    <repositories>\n' +
    '        <repository>\n' +
    '            <id>gitlab-maven</id>\n' +
    '            <url>https://gitlab.com/api/v4/groups/' + properties.gitLabGroup + '/-/packages/maven</url>\n' +
    '        </repository>\n' +
    '    </repositories>\n' +
    '\n' +
    '    <dependencies>\n' +
    '        <dependency>\n' +
    '            <groupId>io.keldysh.public.application.starter</groupId>\n' +
    '            <artifactId>message-flow</artifactId>\n' +
    '            <version>' + properties.messageFlowVersion + '</version>\n' +
    '        </dependency>\n' +
    // '        <!-- Spring Boot starter dependency -->\n' +
    '        <dependency>\n' +
    '            <groupId>org.springframework.boot</groupId>\n' +
    '            <artifactId>spring-boot-starter</artifactId>\n' +
    '            <version>${spring.boot.version}</version>\n' +
    '        </dependency>\n' +
    '    </dependencies>\n' +
    '\n' +
    '    <build>\n' +
    '        <plugins>\n' +
    '            <plugin>\n' +
    '                <groupId>org.springframework.boot</groupId>\n' +
    '                <artifactId>spring-boot-maven-plugin</artifactId>\n' +
    '                <version>${spring.boot.version}</version>\n' +
    '            </plugin>\n' +
    '        </plugins>\n' +
    '    </build>\n' +
    '\n' +
    '</project>\n'

  mavenSetupSecurityExample =
    '<settings xmlns="http://maven.apache.org/SETTINGS/1.0.0"\n' +
    '    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '    xsi:schemaLocation="http://maven.apache.org/SETTINGS/1.0.0 https://maven.apache.org/xsd/settings-1.0.0.xsd">\n' +
    '    <servers>\n' +
    '        <server>\n' +
    '            <id>gitlab-maven</id>\n' +
    '            <configuration>\n' +
    '                <httpHeaders>\n' +
    '                    <property>\n' +
    '                        <name>Deploy-Token</name>\n' +
    '                        <value>YOUR_DEPLOY_TOKEN</value>\n' +
    '                    </property>\n' +
    '                </httpHeaders>\n' +
    '            </configuration>\n' +
    '        </server>\n' +
    '    </servers>\n' +
    '</settings>\n'

  bridgeExample =
    '@SpringBootApplication(proxyBeanMethods = false)\n' +
    'public static class BridgeExampleApplication {\n' +
    '\n' +
    '    public static void main(String[] args) {\n' +
    '        SpringApplication.run(BridgeExampleApplication.class, args);\n' +
    '    }\n' +
    '\n' +
    '    @Component\n' +
    '    static class HandlingBridge {\n' +
    '\n' +
    '        public static final String SOURCE_QUEUE = "source-queue";\n' +
    '        public static final String DESTINATION_ADDRESS = "destination-address";\n' +
    '\n' +
    '        HandlingBridge(MessageClient messageClient) {\n' +
    '\n' +
    '            Type messageType = Long.class;\n' +
    '\n' +
    '            MessageSource<Long> inbound = messageClient.source(SOURCE_QUEUE, messageType);\n' +
    '            MessageSink<Long> outbound = messageClient.sink(DESTINATION_ADDRESS, messageType);\n' +
    '\n' +
    '            inbound.flow().flatMap(l -> Mono.just(l * 2)).connect(outbound).subscribeRetry();\n' +
    '        }\n' +
    '    }\n' +
    '}';

  bridgeExampleConfig =
    'keldysh:\n' +
    '  message-flow:\n' +
    '    artemis:\n' +
    '      host: \<artemis-host\>\n' +
    '      port: 61616\n' +
    '      credentials:\n' +
    '        username: \<artemis-username\>\n' +
    '        password: \<artemis-password\>'

  testScenario =
    '@SpringBootTest\n' +
    '@ExtendWith(ActiveMQArtemisStarterTestContainerExtension.class)\n' +
    'public class BridgeAutoconfigurationExampleApplicationTest {\n' +
    '\n' +
    '    private static final String SOURCE_ADDRESS = "source-address";\n' +
    '    private static final String DESTINATION_QUEUE = "destination-queue";\n' +
    '\n' +
    '    @BeforeAll\n' +
    '    static void init(ClientSessionFactory sessionFactory) {\n' +
    '        final AddressModelConfiguration addressModelConfiguration = new AddressModelConfiguration(Map.of(\n' +
    '                SOURCE_ADDRESS, List.of(BridgeExampleApplication.HandlingBridge.SOURCE_QUEUE),\n' +
    '                BridgeExampleApplication.HandlingBridge.DESTINATION_ADDRESS, List.of(DESTINATION_QUEUE)\n' +
    '        ));\n' +
    '\n' +
    '        \/\/ The last parameter specifies the addresses configuration to be written to broker-message-flow-example.xml .\n' +
    '        BrokerSetupUtility.setupArtemisBroker(sessionFactory, addressModelConfiguration, "message-flow-example");\n' +
    '    }\n' +
    '\n' +
    '    @Autowired\n' +
    '    private MessageClient messageClient;\n' +
    '\n' +
    '    @Test\n' +
    '    public void handlerBridgeTest() {\n' +
    '        MessageSource\n' +
    '                .wrap(Flux.interval(Duration.ofSeconds(1)))\n' +
    '                .flow()\n' +
    '                .connect(messageClient.sink(SOURCE_ADDRESS, Long.class))\n' +
    '                .subscribe();\n' +
    '\n' +
    '        messageClient\n' +
    '                .<Long>source(DESTINATION_QUEUE, Long.class)\n' +
    '                .flow()\n' +
    '                .doOnNext(l -> System.out.println("Destination: " + l))\n' +
    '                .take(10)\n' +
    '                // manually acknowledge and get only the Message\'s contents as a resulting Flux\n' +
    '                .acknowledge()\n' +
    '                .collectList()\n' +
    '                .as(StepVerifier::create)\n' +
    '                .expectNextMatches(list -> list.containsAll(\n' +
    '                        LongStream.of(0, 2, 4, 6, 8, 10, 12, 14, 16, 18).boxed().toList()\n' +
    '                ))\n' +
    '                .expectComplete()\n' +
    '                .verify();\n' +
    '    }\n' +
    '}';

  gradleTestDependencies =
    '    testImplementation \'org.springframework.boot:spring-boot-starter-test\'\n' +
    '    testImplementation \'io.projectreactor:reactor-test:'+ properties.projectReactorVersion +'3.5.4\'\n' +
    '    testImplementation \'org.testcontainers:testcontainers:' + properties.testcontainerVersion +'\'\n' +
    '    testImplementation \'org.testcontainers:junit-jupiter:' + properties.testcontainerVersion + '\'';

  mavenTestDependencies =
    '    <dependency>\n' +
    '        <groupId>org.springframework.boot</groupId>\n' +
    '        <artifactId>spring-boot-starter-test</artifactId>\n' +
    '        <version>${spring.boot.version}</version>\n' +
    '        <scope>test</scope>\n' +
    '    </dependency>\n' +
    '\n' +
    '    <dependency>\n' +
    '        <groupId>io.projectreactor</groupId>\n' +
    '        <artifactId>reactor-test</artifactId>\n' +
    '        <version>' + properties.projectReactorVersion + '</version>\n' +
    '        <scope>test</scope>\n' +
    '    </dependency>\n' +
    '\n' +
    '    <dependency>\n' +
    '        <groupId>org.testcontainers</groupId>\n' +
    '        <artifactId>testcontainers</artifactId>\n' +
    '        <version>' + properties.testcontainerVersion + '</version>\n' +
    '        <scope>test</scope>\n' +
    '    </dependency>\n' +
    '\n' +
    '    <dependency>\n' +
    '        <groupId>org.testcontainers</groupId>\n' +
    '        <artifactId>junit-jupiter</artifactId>\n' +
    '        <version>' + properties.testcontainerVersion + '</version>\n' +
    '        <scope>test</scope>\n' +
    '    </dependency>';
  protected readonly properties = properties;
}
