export const properties = {
  privateLicenseAnchor: "privateLicenseTerms",
  redistributionLicenseAnchor: "redistributionLicenseTerms",
  supportContractAnchor: "professionalSupportContract",
  gitLabGroup: '84921789',
  minJavaVersion: '17',
  messageFlowVersion: '1.1.1',
  messagingVersion: '4.1.1',
  springBootVersion: '3.2.4',
  springDependencyManagementVersion: '1.1.4',
  lombokVersion: '8.6',
  projectReactorVersion: '3.5.4',
  testcontainerVersion: '1.19.3'
}
