import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoginComponent} from "../login.component";
import {Modal} from "bootstrap";
import {Router} from "@angular/router";
import {LoginModalService} from "./login-modal.service";

@Component({
  selector: 'app-login-modal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LoginComponent
  ],
  templateUrl: './login-modal.component.html',
  styleUrl: './login-modal.component.scss'
})
export class LoginModalComponent implements AfterViewInit {

  @ViewChild('loginModal') loginModal!: ElementRef;

  modal!: Modal;

  requestedUrl!: string | null;

  constructor(
    protected loginModalService: LoginModalService,
    private router: Router
  ) {
  }

  ngAfterViewInit() {
    this.modal = new Modal(this.loginModal.nativeElement);

    this.loginModalService.loginRequest.subscribe(value => {
      this.requestedUrl = value
      this.show();
    })

    // this.loginModalService.getModalState().subscribe(isVisible => {
    //   if (isVisible) {
    //     this.show();
    //   } else {
    //     this.hide();
    //   }
    // });
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  hideAndRoute() {
    this.hide();
    if (this.requestedUrl)
      this.router.navigate([this.requestedUrl]).then();
  }
}
