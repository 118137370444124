<div id="plans2" class="py-5">
  <h1 class="display-5 text-center py-5">Plans</h1>
  <div class="py-3"></div>
  <div class="container">
    <div class="row row-cols-1 row-cols-lg-3 g-4"> <!-- Adjusted for responsive 3 columns and added gap -->
      <!--      <div class="row "> &lt;!&ndash; Adjusted for responsive 3 columns and added gap &ndash;&gt;-->
      <div *ngFor="let product of products" class="col">
        <!--        <div *ngFor="let product of products" class="col-md-4 mb-4">-->
        <div class="d-flex flex-column h-100 text-area"> <!-- Flex column and full height -->
          <div class="py-5 px-1 text-center"> <!-- Padding and centering for the title -->
            <h2 class="display-6 mb-4">{{ product.name }}</h2>
          </div>
          <div class="flex-grow-1 px-3"> <!-- Flex grow to use available space -->
            <p>{{ product.description }}</p>
            <ul class="py-3">
              <li *ngFor="let feature of product.features">{{ feature }}</li>
              <li *ngIf="product.prices[0].tryoutPeriod > 0">Free tryout for {{ product.prices[0].tryoutPeriod }} days.</li>
            </ul>
          </div>
          <div class="p-3 py-3"> <!-- Padding -->
            <div *ngFor="let price of product.prices" class="py-3">
              <h4 class="text-center py-3">{{ price.valueInChf / 100 }} CHF / {{ price.billingPeriod }}</h4>
              <div class="d-flex justify-content-center">
<!--                <button *ngIf="price.tryoutPeriod > 0" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#checkoutModal" (click)="select(product, price, true)">Free Trial</button>-->
<!--                <button *ngIf="price.tryoutPeriod <= 0" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#checkoutModal" (click)="select(product, price, false)">Subscribe</button>-->
                <button *ngIf="price.tryoutPeriod > 0" class="btn btn-primary" (click)="select(product, price, true)">Free Trial</button>
                <button *ngIf="price.tryoutPeriod <= 0" class="btn btn-primary" (click)="select(product, price, false)">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
