<!--todo: why does staging banner disappear when clicking href-->
<nav class="navbar col-lg-2">
  <div class="container-fluid">
    <ul class="navbar-nav flex-column">
      <li class="nav-item" *ngFor="let section of sections">
        <a class="nav-link" [class.active]="section.id === activeSection" [href]="'/documentation#' + section.id">{{ section.label }}</a>
        <ul class="nav flex-column ms-2">
          <li class="nav-item" *ngFor="let subSection of section.subSections">
            <a class="nav-link" [class.active]="subSection.id === activeSection" [href]="'/documentation#' + subSection.id">{{ subSection.label }}</a>
            <ul class="nav flex-column ms-2">
              <li class="nav-item" *ngFor="let subSubSection of subSection.subSubSections">
                <a class="nav-link" [class.active]="subSubSection.id === activeSection" [href]="'/documentation#' + subSubSection.id">{{ subSubSection.label }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <hr>
      <a class="nav-link" [routerLink]="['/documentation/javadoc']" target="_blank">Javadoc: Messaging</a>
    </ul>
  </div>
</nav>

