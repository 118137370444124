import {Injectable, NgZone} from '@angular/core';

import {initializeApp} from "firebase/app";
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  getIdToken,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User
} from "firebase/auth";
import {environment} from "../../environments/environment";
import {map, Observable, ReplaySubject} from "rxjs";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthenticationService {
  auth: Auth;
  // private _authStateSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private _authStateSubject: ReplaySubject<User | null> = new ReplaySubject<User | null>(1);

  constructor(zone: NgZone) {
    const app = initializeApp(environment.firebaseConfig);
    this.auth = zone.runOutsideAngular(() => getAuth(app));
    // this._authStateSubject.next(this.auth.currentUser);
    onAuthStateChanged(this.auth, user => {
      this._authStateSubject.next(user);
    });
  }

  async signUp(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  async login(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  async logout() {
    return signOut(this.auth).then(() => window.location.reload());
  }

  async resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  public isAuthenticated(): Observable<boolean> {
    return this.authState$.pipe(map(user => user !== null && user.emailVerified))
  }

  public get authState$() {
    return this._authStateSubject.asObservable();
  }

  async getToken() {
    return new Promise((resolve, reject) => {
      this.authState$.subscribe((user) => {
        if (user) {
          // User is signed in, get the ID token
          getIdToken(user).then((idToken) => {
            resolve(idToken);
          }).catch((error) => {
            console.log("Rejecting token request: " + error)
            reject(error);
          });
        } else {
          // todo: remove me
          console.log("User not signed in.")
          // User is signed out
          // reject('Not signed in');
          resolve(null);
        }
      });
    });
  }
}
